import React, { Fragment } from 'react'
import { Grid, Paper, Typography, List, ListItem, ListItemText } from '@material-ui/core'
import WMS from './WMS'
import WMTS from './WMTS'

const styles = {
    paper: {
        padding: 20,
        marginTop: 10,
        marginBottom: 10,
     }
}
export default  ( {
    connections,
    onSelect,
    item } : any ) =>
    <Grid container>
        <Grid item sm>
            <Paper style={styles.paper}>
                { connections ? Object.keys(connections)?.map( ( group: string ) =>
                    <Fragment key={group}>
                        <Typography
                            variant="h5"
                            style={{textTransform: 'capitalize'}} >
                            {group}
                        </Typography>
                        <List component="ul">
                            { connections[group].map((conn: {[index: string]: []}) =>
                                <ListItem
                                    key={group + "_" + conn['name']} // not sure if this is correct but at least it removes a -> Warning: Each child in a list should have a unique "key" prop.
                                    button>
                                    <ListItemText
                                        primary={conn['name']}
                                        onClick={() => onSelect(group, conn)}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Fragment>
                ) : null }
            </Paper>
        </Grid>
        <Grid item sm>
            <Paper style={styles.paper}>
                {
                    item ?
                        <Fragment>
                            <Typography
                                variant="h6"
                                style={{textTransform: 'capitalize'}} >
                                {item.name}
                            </Typography>
                            {
                                item.category === 'wms' ? <WMS/> :
                                item.category === 'wmts' ? <WMTS/> :
                                'Category "'+item.category+'" not yet supported'
                            }
                            </Fragment>
                    : "Nothing selected, please from left panel"
                }
            </Paper>
        </Grid>
    </Grid>