import React, {Component, Fragment} from 'react';
import './App.css';
import {Header, Footer} from './Layouts'
import Content from './Contents'
import { GetApi, Api } from '../api/api'


interface AppState {
    api?: Api,
    categories?: string[],
    category?: string,
    connections?: { [index: string]: [] },
    selectedItem?: any,
}


export default class App extends Component<{}, AppState> {


    constructor(props: {}) {
        super(props)
        this.state = { }
    }

    componentDidMount() {
        this.getApiAsync();
    }

    handleCategorySelect = (category: string) => {
        this.setState({
            category: category
        })
    }

    handleContentItemSelected = (category: string, item: any) => {
        item.category = category;
        this.setState( {
            selectedItem: item
        })
    }

    getConnectionsByType(type: string | undefined):  { [index: string]: [] } | undefined {
        if (this.state.connections && type) {
            let t:any = {}
            t[type] = this.state.connections[type];;
            return t;
        }
        return this.state.connections;
    }
    public render() {
        return (
            <Fragment>
                <Header/>
                <Content
                    connections={  this.getConnectionsByType(this.state.category) }
                    onSelect={ this.handleContentItemSelected }
                    item= { this.state.selectedItem }
                />
                <Footer
                    categories={ this.state.categories }
                    category={ this.state.category }
                    onSelect={ this.handleCategorySelect }/>
            </Fragment>
        )
    }

    private async getApiAsync() {
        const a = await GetApi();
        a.getWMTSProxyApi().getWmtsProxyTypes()
        .then( (options) => this.setState( {
            api: a,
            categories: options
        } ));

        a.getWMTSProxyApi().getWmtsProxiesByType()
        .then( (conns) => {
            this.setState( {
                connections: conns
            })
        })
    }
}