import { WMTSProxyAPI } from "./WMTSProxy";

export interface Api {
    // getUserInfo(): UserInfo,

    // logout(): void;
    // login(username: string, password: string): void;
    // signup(data: {}): void;

    // addLoginListener(listener: LoginListener): void;
    // removeLoginListener(listener: LoginListener): void;

    getWMTSProxyApi(): WMTSProxyAPI;


}



function getBackendUrl(path: string): string {
    if (!path.startsWith('/'))
        path = '/' + path
    if (process.env.NODE_ENV !== 'production') {
        return "http://127.0.0.1:8000" + path;
    }
    return "https://gis.iconiqlabs.com" + path;
}

let api: Api | undefined;

export async function GetApi(): Promise<Api> {
    if (!api) {
        api = await createApi();
    }
    return api;
}


async function createApi(): Promise<Api> {

    // const response = await fetch(backendBaseUrl() + '/api/userinfo'); // TODO handle error
    // const userInfo = await response.json() as UserInfo;
    
    // return new ApiImpl(userInfo as UserInfo);
    return new ApiImpl();
}

export class ApiImpl implements Api {
    _wmtsApi: WMTSProxyAPI;

    constructor()
    {
        this._wmtsApi = new WMTSProxyAPI(this);
    }

    getWMTSProxyApi(): WMTSProxyAPI {
        return this._wmtsApi;
    }



    public async postJson(path: string, data: {}): Promise<Response> {
        return await fetch(getBackendUrl(path), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
    }
}