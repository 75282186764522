import { ApiImpl } from './api'

export class WMTSProxyAPI {
    _api: ApiImpl;
    constructor(api: ApiImpl) {
        this._api = api;
    }

    public async getWmtsProxyTypes(): Promise<string[]> {
        return this._api.postJson('/wmtsproxy/driver/list', {})
        .then( res => res.json() )
        .then( json => json['types']);
    }

    public async getWmtsProxiesByType(type: string | void): Promise<{}> {

        return this._api.postJson('/wmtsproxy/connection/'+(type?type:'all') +'/list', {})
        .then( res => res.json() )
    }
}


// const wmtsProxiesByType = (type: string) => {
//     return [
//         {
//             name: 'name1',
//             prop: 'prop1'
//         },
//         {
//             name: 'name2',
//             prop: 'prop2'
//         },
//         {
//             name: 'name2',
//             prop: 'prop2'
//         },
//     ]
// }
