import React, { Fragment } from 'react'
import { Paper, Tabs, Tab } from '@material-ui/core'


export default ( { categories, category,  onSelect }:any ) =>  {
    const index = category
        ? categories.findIndex((group: string) => group === category) + 1
        : 0;
    return (
        <Fragment>
            <Paper>
                <Tabs
                    value={index}
                    onChange={(e, index) => {
                        onSelect(index === 0 ? '' : categories[index-1])
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="All" />
                    {categories?.map((group: string) =>
                        <Tab key={group} label={group}/>
                    )}

                </Tabs>
            </Paper>
            <footer style={{ padding: '1rem', textAlign: 'right' }}>
                Copyright © {new Date().getFullYear()} Iconiq Labs.
            </footer>
        </Fragment>
      )
  }